import React, {useState} from 'react';
import {compose} from 'recompose';
import {connectToStore} from '../components/Provider';
import requireCustomer from '../middleware/requireCustomer';
import hydrateCustomerDetails from '../middleware/hydrateCustomerDetails';
import Helmet from 'react-helmet';
import AccountMenu from '../components/AccountMenu';
import SubscriptionsSubmenu from '../components/SubscriptionsSubmenu';
import BillingAddressEdit from '../components/BillingAddressEdit';
import PaymentMethodEdit from '../components/PaymentMethodEdit';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);

const SubscriptionsBilling = ({
	rechargeCustomer,
	paymentMethod,
	isFetchingCustomerDetails,
	updateRechargeCustomer,
	hydrateCustomerDetails,
}) => {
	const [isEditingPaymentMethod, setIsEditingPaymentMethod] = useState(false);
	const [isEditingBillingAddress, setIsEditingBillingAddress] = useState(
		false,
	);

	return (
		<div className="mt3 mb6 mt8--lg mb8--lg">
			<Helmet title="Subscriptions Billing - Genexa" />
			<AccountMenu />

			<div className="grid-container contained mt3 mt5--lg">
				<div className="row align--center">
					<div className="col c10--lg">
						<SubscriptionsSubmenu />

						{!isEditingBillingAddress && !isEditingPaymentMethod && (
							<div className="bg--gray-bg">
								{isFetchingCustomerDetails &&
									!!rechargeCustomer && <p>Loading…</p>}
								{!isFetchingCustomerDetails &&
									!rechargeCustomer && (
										<p className="p10 tc fw--800">
											You don't have any subscriptions
											yet!
										</p>
									)}
								{!!rechargeCustomer && (
									<div className="p2">
										{paymentMethod && (
											<React.Fragment>
												<p>Card on File</p>
												<hr className="mt2 mb2" />
												<div className="df jcb ais fdc fdr--md mb4">
													<div className="mb1 mb0--md">
														<p>
															<span>
																{paymentMethod.card_brand &&
																	`${paymentMethod.card_brand}`}
															</span>
															<span>
																{paymentMethod.card_last4 &&
																	` ending in ${paymentMethod.card_last4}`}
															</span>
														</p>
														<p>
															{paymentMethod.card_exp_month &&
																paymentMethod.card_exp_year && (
																	<span>
																		{`Expires ${paymentMethod.card_exp_month}/${paymentMethod.card_exp_year}`}
																	</span>
																)}
														</p>
													</div>
													<button
														title="Update Card"
														aria-label="Update Card"
														className="link--underline-invert-small"
														onClick={() =>
															setIsEditingPaymentMethod(
																true,
															)
														}>
														Update Card
													</button>
												</div>
											</React.Fragment>
										)}
										<p>Billing Information</p>
										<hr className="mb2 mt2" />
										<div className="df jcb ais fdc fdr--md">
											<div className="mb1 mb0--md">
												<p>
													{
														rechargeCustomer.first_name
													}{' '}
													{rechargeCustomer.last_name}
												</p>
												<p>
													{
														rechargeCustomer.billing_address1
													}{' '}
													{
														rechargeCustomer.billing_address2
													}
												</p>
												<p>
													{
														rechargeCustomer.billing_city
													}
													,{' '}
													{
														rechargeCustomer.billing_province
													}{' '}
													{
														rechargeCustomer.billing_zip
													}
												</p>
												<p>
													{
														rechargeCustomer.billing_country
													}
												</p>
												<p>{rechargeCustomer.email}</p>
											</div>
											<button
												title="Edit"
												aria-label="Edit"
												className="link--underline-invert-small"
												onClick={() =>
													setIsEditingBillingAddress(
														true,
													)
												}>
												Edit
											</button>
										</div>
									</div>
								)}
							</div>
						)}

						{isEditingPaymentMethod && (
							<Elements stripe={stripePromise}>
								<PaymentMethodEdit
									{...paymentMethod}
									updateRechargeCustomer={
										updateRechargeCustomer
									}
									close={() =>
										setIsEditingPaymentMethod(false)
									}
								/>
							</Elements>
						)}

						{isEditingBillingAddress && (
							<BillingAddressEdit
								close={() => {
									setIsEditingBillingAddress(false);
									hydrateCustomerDetails();
								}}
								rechargeCustomer={rechargeCustomer}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = ({
	rechargeCustomer,
	paymentMethod,
	isFetchingCustomerDetails,
	customer,
}) => ({
	rechargeCustomer,
	paymentMethod,
	isFetchingCustomerDetails,
	customer,
});

const mapStoreToProps = ({updateRechargeCustomer, hydrateCustomerDetails}) => ({
	updateRechargeCustomer,
	hydrateCustomerDetails,
});

export default compose(
	requireCustomer,
	hydrateCustomerDetails,
	connectToStore(mapStateToProps, mapStoreToProps),
)(SubscriptionsBilling);
