import React, {useState} from 'react';
import classNames from 'classnames';
import {decode} from '../utils/shopify-gid';
import {Link} from 'gatsby';
import {compose} from 'recompose';
import {connectToStore} from '../components/Provider';
import requireCustomer from '../middleware/requireCustomer';
import hydrateCustomerAccount from '../middleware/hydrateCustomerAccount';
import Helmet from 'react-helmet';
import AccountMenu from '../components/AccountMenu';
import {deleteAddress} from '../api/account';
import Radio from '../components/Radio';

const AddressDetail = ({
	customerToken,
	id,
	firstName,
	lastName,
	address1,
	address2,
	city,
	province,
	zip,
	country,
	phone,
	isDefault,
	hydrateCustomerAccount,
}) => {
	const [isDeleting, setIsDeleting] = useState(false);

	const onDeleteAddress = () => {
		if (!confirm('Are you sure you want to delete this address?')) return;

		setIsDeleting(true);

		deleteAddress(customerToken, id)
			.then(res => {
				if (!res.ok) {
					throw res;
				} else {
					return res.json();
				}
			})
			.then(({addressId}) => {
				setIsDeleting(false);
				hydrateCustomerAccount();
			})
			.catch(error => {
				setIsDeleting(false);
			});
	};

	return (
		<div className="bg--gray-bg p2">
			<div className="row mb1">
				<div className="col c6--md mb1 mb0--md">
					<p className="fw--800 mb1">
						{firstName} {lastName}
					</p>
					<p>
						{address1} <br />
						{address2} <br />
						{city}, {province} {zip} <br />
						{country} <br />
						{phone}
					</p>
				</div>

				<div className="col c6--md">
					<div className="df fdr jce--md">
						<div className="df fdr aic">
							<Radio className="fg0" active={isDefault} small />
							<label className="fg1 pl1">Default</label>
						</div>
					</div>
				</div>
			</div>
			<p className="df fdr">
				<Link
					className="db link--underline-invert-small mr2"
					to={`/account/addresses/${decode(id).id}`} aria-label="Edit">
					Edit
				</Link>
				<button
					title="Delete"
					aria-label="Delete"
					disabled={isDeleting}
					onClick={onDeleteAddress}
					className={classNames('db link--underline-invert-small', {
						loading: isDeleting,
					})}>
					Delete
				</button>
			</p>
		</div>
	);
};

const AddressList = ({
	customerToken,
	addresses = [],
	defaultAddress,
	isFetchingAccountDetails,
	hydrateCustomerAccount,
	pageContext,
}) => {
	const loadingState = isFetchingAccountDetails && !addresses.length && (
		<p className="bg--gray-bg p4 p10--lg tc fw--800">Loading…</p>
	);

	const emptyState = !isFetchingAccountDetails && !addresses.length && (
		<p className="bg--gray-bg p4 p10--lg tc fw--800">
			You don't have any addresses yet.
		</p>
	);

	const addressList = addresses.map((address, index) => (
		<div key={index} className="mb2">
			<AddressDetail
				{...address}
				isDefault={address.id === defaultAddress.id}
				hydrateCustomerAccount={hydrateCustomerAccount}
				customerToken={customerToken}
			/>
		</div>
	));

	return (
		<div className="mt3 mb6 mt8--lg mb8--lg">
			<Helmet title="Addresses - Genexa" />
			<AccountMenu />

			<div className="grid-container contained">
				<div className="row align--center">
					<div className="col c10--lg">
						<h2 className="serif--lg serif--xxl--lg mt5 mb2 mt8--lg mb2--lg">
							Addresses
						</h2>
						<p className="mb5 mb8--lg rich-text fg1">
							<span className="fw--800">To update the address on your subscription,</span> <Link to="/account/subscriptions" className="addresslink"> click here.</Link>
						</p>
						{loadingState}
						{emptyState}
						{addressList}
						<div className="mt4">
							<Link
								to="/account/addresses/new"
								className="button--primary" aria-label="Add New Address">
								Add New Address
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = ({
	customerToken,
	addresses,
	defaultAddress,
	isFetchingAccountDetails,
}) => ({
	customerToken,
	addresses,
	defaultAddress,
	isFetchingAccountDetails,
});

const mapStoreToProps = ({hydrateCustomerAccount}) => ({
	hydrateCustomerAccount,
});

export default compose(
	requireCustomer,
	hydrateCustomerAccount,
	connectToStore(mapStateToProps, mapStoreToProps),
)(AddressList);
