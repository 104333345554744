import {compose, lifecycle} from 'recompose';
import {connectToStore} from '../components/Provider';

const mapStateToProps = () => ({});
const mapStoreToProps = ({hydrateCustomerAccount}) => ({hydrateCustomerAccount});

export default compose(
	connectToStore(mapStateToProps, mapStoreToProps),
	lifecycle({
		componentDidMount() {
			this.props.hydrateCustomerAccount();
		}
	}),
);
