import React from 'react';
import classNames from 'classnames';
import {Link} from 'gatsby';
import {connectToStore} from '../components/Provider';

const AccountMenu = ({logout}) => {
	const getAddressBookLinkProps = ({
		isPartiallyCurrent,
		isCurrent,
		className,
	}) => ({
		className: classNames('db link--underline fw--800', {
			active: isPartiallyCurrent || isCurrent,
		}),
	});

	return (
		<div className="grid-container contained">
			<h1 className="serif--xl serif--xxxl--lg tc mb3 mb5--lg">
				Your Account
			</h1>

			<div className="grid-container-reset">
				<div className="ofx--scroll grid-container">
					<ul className="df fdr jcs jcc--md aic ws--nowrap">
						<li className="pb2 pr3 pb3--md pr6--md">
							<Link
								className="db link--underline fw--800"
								activeClassName="active"
								aria-label="Your Orders"
								to="/account">
								Your Orders
							</Link>
						</li>
						<li className="pb2 pr3 pb3--md pr6--md">
							<Link
								getProps={getAddressBookLinkProps}
								className="db link--underline fw--800"
								activeClassName="active"
								aria-label="Addresses"
								to="/account/addresses">
								Addresses
							</Link>
						</li>
						<li className="pb2 pr3 pb3--md pr6--md">
							<Link
								className="db link--underline fw--800"
								activeClassName="active"
								aria-label="Manage Subscription"
								to="/account/subscriptions">
								Manage Subscription
							</Link>
						</li>
						<li className="pb2 pb3--md pr3 pr0--md">
							<button
								title="Logout"
								aria-label="Logout"
								className="db link--underline fw--800"
								onClick={logout}>
								Log Out
							</button>
						</li>
					</ul>
				</div>
			</div>

			<div className="row align--center">
				<div className="col c10--lg">
					<hr />
				</div>
			</div>
		</div>
	);
};

const mapStoreToProps = ({logout}) => ({
	logout,
});

const mapStateToProps = () => ({});
export default connectToStore(mapStateToProps, mapStoreToProps)(AccountMenu);
