import React, {Component} from 'react';
import classNames from 'classnames';
import {login} from '../api/account';
import {connectToStore} from '../components/Provider';
import {navigate} from 'gatsby';
import {Link} from 'gatsby';
import {email as emailRegex} from 'magic-tricks/lib/regex';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import Helmet from 'react-helmet';

const INITIAL_STATE = {
	values: {
		email: '',
		password: '',
	},
	isSubmitting: false,
	submitError: false,
	showErrors: false,
	formErrors: {
		email: false,
		password: false,
	},
};

const FORM_FIELDS = [
	{
		name: 'email',
		type: 'email',
		required: true,
	},
	{
		name: 'password',
		type: 'text',
		required: true,
	},
];

class Login extends Component {
	constructor() {
		super();

		this.state = {...INITIAL_STATE};
	}

	onChange = event => {
		this.setState(
			{
				values: {
					...this.state.values,
					[event.currentTarget.name]: event.currentTarget.value,
				},
			},
			() => {
				if (this.state.showErrors) {
					this.validateForm();
				}
			},
		);
	};

	validateForm = () => {
		const {values = {}, formErrors = {}} = this.state;

		const requiredFields = filter(FORM_FIELDS, field => field.required);
		const emailFields = filter(
			FORM_FIELDS,
			field => field.type === 'email',
		);
		const updatedFormErrors = {...formErrors};

		let showErrors = false;

		forEach(requiredFields, field => {
			if (!values[field.name]) {
				updatedFormErrors[field.name] = true;
				showErrors = true;
			} else {
				updatedFormErrors[field.name] = false;
			}
		});

		forEach(emailFields, field => {
			if (!emailRegex.test(values[field.name])) {
				updatedFormErrors[field.name] = true;
				showErrors = true;
			} else {
				updatedFormErrors[field.name] = false;
			}
		});

		this.setState({
			showErrors,
			formErrors: updatedFormErrors,
		});

		return showErrors;
	};

	onSubmit = event => {
		event.preventDefault();

		const errors = this.validateForm();

		if (errors) return;

		this.setState({isSubmitting: true});

		const {email, password} = this.state.values;

		login(email, password)
			.then(res => {
				if (!res.ok) {
					throw res;
				} else {
					return res.json();
				}
			})
			.then(
				({token, customer, rechargeAccessToken, rechargeCustomer}) => {
					this.props.hydrateTokenFromServer({
						token,
						customer,
						rechargeAccessToken,
						rechargeCustomer,
					});
					this.props.hydrateCheckout();
					this.props.npsDelighted(customer);
					navigate('/account');
				},
			)
			.catch(error => {
				this.setState({
					isSubmitting: false,
					submitError: true,
				});
			});
	};

	render() {
		const {
			showErrors,
			formErrors,
			values,
			isSubmitting,
			submitError,
		} = this.state;

		return (
			<div className="grid-container contained mt4 mb4 mt8--lg mb18--lg">
				<Helmet title="Login - Genexa" />
				<div className="row align--center">
					<div className="col c6--md c12">
						<h1 className="serif--lg serif--xxxl--lg mb5 mb9--lg tc">
							Sign In
						</h1>
					</div>
				</div>

				<form  title="Update Account" aria-label="Update Accout"  onSubmit={this.onSubmit} className="db x mb3 mb5--lg">
					<div className="row mb2 align--center">
						<div className="col c6--md c12">
							<input
								name="email"
								type="email"
								onChange={this.onChange}
								value={values.email}
								className="x input--primary"
								placeholder="Email*"
							/>
							{formErrors.email && (
								<p className="mt1 color--red sans--xs fw--800">
									Please enter a valid email.
								</p>
							)}
						</div>
					</div>

					<div className="row mb3 mb5--lg align--center">
						<div className="col c6--md c12">
							<input
								name="password"
								onChange={this.onChange}
								value={values.password}
								className="x input--primary"
								type="password"
								placeholder="Password*"
							/>
							{formErrors.password && (
								<p className="mt1 color--red sans--xs fw--800">
									Password must be at least 6 characters.
								</p>
							)}
						</div>
					</div>

					<div className="row align--center">
						<div className="col c6--sm c3--md mb1 mb0--sm">
							<button
								title="Sign In"
								aria-label="Sign In"
								disabled={isSubmitting || showErrors}
								type="submit"
								className={classNames('button--primary db x', {
									error: showErrors,
									'cs--loading': isSubmitting,
								})}>
								Sign In
							</button>
						</div>
						<div className="col c6--sm c3--md">
							<Link to="/" className="button--secondary db x" aria-label="Return Home">
								Return Home
							</Link>
						</div>
					</div>

					{submitError && (
						<p className="mt1 tc color--red fw--800 sans--xs">
							Email or password is incorrect.
						</p>
					)}
				</form>

				<p className="tc mb1">
					<Link
						to="/account/forgot"
						className="link--underline-invert-small" aria-label="Forgot Password">
						Forgot password?
					</Link>
				</p>
				<p className="tc mb1">
					Not a Genexa Member?{' '}
					<Link
						to="/account/register"
						className="link--underline-invert-small" aria-label="Create an Account">
						Create an account!
					</Link>
				</p>
			</div>
		);
	}
}

const mapStateToProps = () => ({});

const mapStoreToProps = ({hydrateTokenFromServer,hydrateCheckout,npsDelighted}) => ({
	hydrateTokenFromServer,
	hydrateCheckout,
	npsDelighted,
});

export default connectToStore(mapStateToProps, mapStoreToProps)(Login);
