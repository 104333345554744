import React, {useState} from 'react';
import classNames from 'classnames';
import pluralize from 'magic-tricks/lib/pluralize';
import centsToPriceNoTrailingZeros from 'magic-tricks/lib/centsToPriceNoTrailingZeros';
import format from 'date-fns/format';
import {activateSubscription} from '../api/account';

export default ({
	rechargeAccessToken,
	subscription,
	onEdit,
	onCancel,
	onReactivate,
}) => {
	const [isActivating, setIsActivating] = useState(false);

	const onActivate = () => {
		setIsActivating(true);

		activateSubscription({
			rechargeAccessToken,
			subscriptionId: subscription.id,
		})
			.then(res => {
				if (!res.ok) {
					throw res;
				}
			})
			.then(() => {
				setIsActivating(false);
				onReactivate();
			})
			.catch(error => {
				setIsActivating(false);
				console.log(error);
			});
	};

	return (
		<div className="bg--gray-bg p2">
			<p className="mb1 fw--800">
				{subscription.address.first_name}{' '}
				{subscription.address.last_name}
			</p>
			<p>
				{subscription.address.address1} {subscription.address.address2}
			</p>
			<p>
				{subscription.address.city}, {subscription.address.province}{' '}
			</p>
			<p className="mb2">{subscription.address.country}</p>

			<div className="subscription-list__grid show--md">
				<p className="subscription-list__grid-item-lg">Product</p>
				<p className="show--md">Quantity</p>
				<p className="show--md">Frequency</p>
				<p>Price</p>
				<p className="subscription-list__grid-item-md show--md">
					Next Charge
				</p>

				<hr className="subscription-list__grid-row-divider mt2 mb2" />

				<p className="subscription-list__grid-item-lg ">
					{subscription.product_title}
				</p>
				<p>{subscription.quantity}</p>
				<p>
					{subscription.order_interval_frequency}{' '}
					{pluralize(
						subscription.order_interval_unit,
						subscription.order_interval_frequency,
					)}
				</p>
				<p>{centsToPriceNoTrailingZeros(subscription.price * 100)}</p>
				<p className="subscription-list__grid-item-md">
					{subscription.cancelled_at
						? 'Cancelled'
						: format(
								new Date(subscription.next_charge_scheduled_at),
								'MMM d, yyyy',
						  )}
				</p>
				<div className="subscription-list__grid-item-md df fdr jce">
					{subscription.cancelled_at ? (
						<button
							title="Reactivate" 
							aria-label="Reactivate"
							disabled={isActivating}
							className={classNames(
								'link--underline-invert-small db mr2',
								{
									loading: isActivating,
								},
							)}
							onClick={onActivate}>
							Reactivate
						</button>
					) : (
						<button
							title="Cancel" 
							aria-label="Cancel"
							className="link--underline-invert-small db mr2"
							onClick={onCancel}>
							Cancel
						</button>
					)}
					<button
						title="Edit" 
						aria-label="Edit"
						onClick={onEdit}
						className="link--underline-invert-small db">
						Edit
					</button>
				</div>
			</div>

			<div className="hide--md subscription-list__grid">
				<p className="subscription-list__grid-item-lg ">Product</p>
				<p>Price</p>
				<p className=" subscription-list__grid-item-md show--md">
					Next Charge
				</p>

				<hr className="subscription-list__grid-row-divider mt2 mb2" />

				<div>
					<p className="subscription-list__grid-item-lg">
						{subscription.product_title}
					</p>
					<br />
					<p>{subscription.quantity}</p>
					<br />
					<p>
						Frequency: <br />
						{subscription.order_interval_frequency}{' '}
						{pluralize(
							subscription.order_interval_unit,
							subscription.order_interval_frequency,
						)}
					</p>
				</div>

				<div>
					<p>
						{centsToPriceNoTrailingZeros(subscription.price * 100)}
					</p>
					<br />
					<p className="subscription-list__grid-item-md">
						{'Next Charge: '}
						<br />
						{subscription.cancelled_at
							? 'Cancelled'
							: format(
									new Date(
										subscription.next_charge_scheduled_at,
									),
									'MMM d, yyyy',
							  )}
					</p>
				</div>

				<div className="df fdr mt2">
					{subscription.cancelled_at ? (
						<button
							title="Reactivate" 
							aria-label="Reactivate"
							disabled={isActivating}
							className={classNames(
								'link--underline-invert-small db mr2',
								{
									loading: isActivating,
								},
							)}
							onClick={onActivate}>
							Reactivate
						</button>
					) : (
						<button
							title="Cancel" 
							aria-label="Cancel"
							className="link--underline-invert-small db mr2"
							onClick={onCancel}>
							Cancel
						</button>
					)}
					<button
						title="Edit" 
						aria-label="Edit"
						onClick={onEdit}
						className="link--underline-invert-small">
						Edit
					</button>
				</div>
			</div>
		</div>
	);
};
