import React from 'react';
import format from 'date-fns/format';

const SkipOrderModal = ({nextOrderDate, onClose}) => {
	const FORMAT = 'MMM d, yyyy';

	return (
		<div className="pf fill z3">
			<div className="pa fill bg--black o5 z0" />
			<div className="z1 pa fill df aic jcc">
				<div className="bg--white p2 cancelation-modal">
					{/* first step */}
					<div>
						<div className="df jcb mb2 ais">
							<p className="fw--800 mr5">
								Your shipment has been skipped.
							</p>

							<button title="Close" aria-label="Close" onClick={onClose}>
								<img src="/images/icon-close.svg" alt="close" />
							</button>
						</div>
						<div className="mb3">
							<p>Your next shipment will arrive on:</p>
							<p>{format(new Date(nextOrderDate), FORMAT)}</p>
						</div>
						<button title="Continue" aria-label="Continue" className="button--primary" onClick={onClose}>
							Continue
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SkipOrderModal;
