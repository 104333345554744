import React from 'react';
import {Link} from 'gatsby';

const SubscriptionsSubmenu = () => (
	<div className="mb4">
		<h1 className="serif--lg serif--xxl--lg tc mb3">Subscriptions</h1>
		<ul className="df jcc aic">
			<li>
				<Link
					className="fw--800 link--underline mr3"
					activeClassName="active"
					to="/account/subscriptions" aria-label="Active Subscriptions">
					Active Subscriptions
				</Link>
			</li>
			<li>
				<Link
					className="fw--800 link--underline"
					activeClassName="active"
					to="/account/subscriptions/billing" aria-label="Billing Information">
					Billing Information
				</Link>
			</li>
		</ul>
	</div>
);

export default SubscriptionsSubmenu;
