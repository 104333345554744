import React, {Component} from 'react';
import classNames from 'classnames';
import {Link} from 'gatsby';
import {connectToStore} from '../components/Provider';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import {activate} from '../api/account';
import {navigate} from 'gatsby';
import {encode} from '../utils/shopify-gid';
import Helmet from 'react-helmet';

const INITIAL_STATE = {
	values: {
		password: '',
	},
	showErrors: false,
	formErrors: {
		password: false,
	},
	isSubmitting: false,
	submitError: false,
};

const FORM_FIELDS = [
	{
		name: 'password',
		type: 'password',
		required: true,
	},
];

class ActivateAccount extends Component {
	constructor() {
		super();

		this.state = {...INITIAL_STATE};
	}

	onChange = event => {
		this.setState(
			{
				values: {
					...this.state.values,
					[event.currentTarget.name]: event.currentTarget.value,
				},
			},
			() => {
				if (this.state.showErrors) {
					this.validateForm();
				}
			},
		);
	};

	validateForm = () => {
		const {values = {}, formErrors = {}} = this.state;

		const requiredFields = filter(FORM_FIELDS, field => field.required);
		const passwordFields = filter(
			FORM_FIELDS,
			field => field.type === 'password',
		);
		const updatedFormErrors = {...formErrors};

		let showErrors = false;

		forEach(requiredFields, field => {
			if (!values[field.name]) {
				updatedFormErrors[field.name] = true;
				showErrors = true;
			} else {
				updatedFormErrors[field.name] = false;
			}
		});

		forEach(passwordFields, field => {
			if (values[field.name].length < 6) {
				updatedFormErrors[field.name] = true;
				showErrors = true;
			} else {
				updatedFormErrors[field.name] = false;
			}
		});

		this.setState({
			showErrors,
			formErrors: updatedFormErrors,
		});

		return showErrors;
	};

	onSubmit = event => {
		event.preventDefault();

		const errors = this.validateForm();

		if (errors) return;

		this.setState({isSubmitting: true});

		const customerId = encode('Customer', this.props.id, {
			accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
		});

		activate(customerId, this.props.token, this.state.values.password)
			.then(res => {
				if (!res.ok) {
					throw res;
				} else {
					return res.json();
				}
			})
			.then(
				({token, customer, rechargeAccessToken, rechargeCustomer}) => {
					this.props.hydrateTokenFromServer({
						token,
						customer,
						rechargeAccessToken,
						rechargeCustomer,
					});
					navigate('/account');
				},
			)
			.catch(error => {
				this.setState({
					isSubmitting: false,
					submitError: true,
				});
			});
	};

	render() {
		const {
			showErrors,
			formErrors,
			values,
			submitError,
			isSubmitting,
		} = this.state;

		return (
			<div className="grid-container contained mt4 mb4 mt8--lg mb18--lg">
				<Helmet title="Activate Account - Genexa" />
				<div className="row align--center">
					<div className="col c6--md c12">
						<h1 className="serif--lg serif--xxxl--lg mb5 mb9--lg tc">
							Activate Account
						</h1>
					</div>
				</div>

				<form  title="Activate Account" aria-label="Activate Account" onSubmit={this.onSubmit} className="db x mb3 mb5--lg">
					<div className="row align--center mb3 mb5--lg">
						<div className="col c6--md c12">
							<input
								name="password"
								onChange={this.onChange}
								value={values.password}
								className="x input--primary"
								type="password"
								placeholder="Password*"
							/>
							{formErrors.password && (
								<p className="mt1 color--red sans--xs fw--800">
									Password must be at least 6 characters.
								</p>
							)}
						</div>
					</div>

					<div className="row align--center">
						<div className="col c6--sm c3--md mb2 mb0--md">
							<button
								title="Activate Account" 
								aria-label="Activate Account"
								disabled={isSubmitting || showErrors}
								type="submit"
								className={classNames('button--primary x', {
									error: showErrors,
									'cs--loading': isSubmitting,
								})}>
								Activate Account
							</button>
						</div>
						<div className="col c6--sm c3--md">
							<Link to="/" className="button--secondary db x" aria-label="Return Home">
								Return Home
							</Link>
						</div>
					</div>

					{submitError && (
						<p className="mt2 tc color--red sans--xs fw--800">
							There was an error activating your account.
						</p>
					)}
				</form>
			</div>
		);
	}
}

const mapStateToProps = () => ({});

const mapStoreToProps = ({hydrateTokenFromServer}) => ({
	hydrateTokenFromServer,
});

export default connectToStore(
	mapStateToProps,
	mapStoreToProps,
)(ActivateAccount);
