import React from 'react';
import {compose} from 'recompose';
import {connectToStore} from '../components/Provider';
import requireCustomer from '../middleware/requireCustomer';
import hydrateCustomerAccount from '../middleware/hydrateCustomerAccount';
import format from 'date-fns/format';
import capitalize from 'magic-tricks/lib/capitalize';
import Helmet from 'react-helmet';
import AccountMenu from '../components/AccountMenu';

const OrderList = ({orders = [], isFetchingAccountDetails, pageContext}) => {
	const loadingState = isFetchingAccountDetails && !orders.length && (
		<p className="p4 p10--lg tc fw--800">Loading…</p>
	);

	const emptyState = !isFetchingAccountDetails && !orders.length && (
		<p className="p4 p10--lg tc fw--800">You don't have any orders yet!</p>
	);

	const orderList = !!orders.length && (
		<div className="p2">
			<div className="row">
				<div className="col c6 order-list__col">
					<p>Order</p>
				</div>
				<div className="col c6 order-list__col show--md">
					<p>Date</p>
				</div>
				<div className="col c6 order-list__col">
					<p>Status</p>
				</div>
				<div className="col c6 order-list__col  show--md">
					<p>Total</p>
				</div>
				<div className="col c6 order-list__col  show--md">
					<p>Order Details</p>
				</div>
			</div>

			{orders.map(order => (
				<React.Fragment key={order.id}>
					<hr className="mt2 mb2" />

					<div className="row show--md">
						<div className="col order-list__col">
							<p>#{order.orderNumber}</p>
						</div>
						<div className="col order-list__col">
							<p>
								{format(
									new Date(order.processedAt),
									'MMM d, YYY',
								)}
							</p>
						</div>
						<div className="col order-list__col">
							<p>
								{capitalize(
									order.financialStatus.toLowerCase(),
								)}
								{', '}
								{capitalize(
									order.fulfillmentStatus.toLowerCase(),
								)}
							</p>
						</div>
						<div className="col order-list__col">
							<p>${order.totalPrice}</p>
						</div>
						<div className="col order-list__col">
							<p>
								<a
								    aria-label="Order Details"
									target="_blank"
									className="link--underline-invert-small"
									href={order.statusUrl}>
									Details
								</a>
							</p>
						</div>
					</div>

					<div className="row hide--md">
						<div className="col c6">
							<p>#{order.orderNumber}</p>
							<p>
								{format(
									new Date(order.processedAt),
									'MMM d, YYY',
								)}
							</p>
							<p>
								<a
									aria-label="Order Details"
									target="_blank"
									className="link--underline-invert-small"
									href={order.statusUrl}>
									Details
								</a>
							</p>
						</div>
						<div className="col c6">
							<p>${order.totalPrice}</p>
							<p>
								{capitalize(
									order.financialStatus.toLowerCase(),
								)}
								{', '}
								{capitalize(
									order.fulfillmentStatus.toLowerCase(),
								)}
							</p>
						</div>
					</div>
				</React.Fragment>
			))}
		</div>
	);

	return (
		<div className="mt3 mb6 mt8--lg mb8--lg">
			<Helmet title="Account - Genexa" />
			<AccountMenu />

			<div className="grid-container contained">
				<div className="row align--center">
					<div className="col c10--lg">
						<h2 className="serif--lg serif--xxl--lg mt5 mb5 mt8--lg mb8--lg">
							Order History
						</h2>

						<div className="bg--gray-bg">
							{loadingState}
							{emptyState}
							{orderList}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = ({orders, isFetchingAccountDetails}) => ({
	orders,
	isFetchingAccountDetails,
});

export default compose(
	requireCustomer,
	hydrateCustomerAccount,
	connectToStore(mapStateToProps),
)(OrderList);
