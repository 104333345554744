import React, {Component} from 'react';
import classNames from 'classnames';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import {recover} from '../api/account';
import Helmet from 'react-helmet';
import {Link} from 'gatsby';

const INITIAL_STATE = {
	values: {
		email: '',
	},
	showErrors: false,
	formErrors: {
		email: false,
	},
	isSubmitting: false,
	isSuccess: false,
	submitError: false,
};

const FORM_FIELDS = [
	{
		name: 'email',
		type: 'email',
		required: true,
	},
];

export default class ForgotPassword extends Component {
	constructor() {
		super();

		this.state = {...INITIAL_STATE};
	}

	onChange = event => {
		this.setState(
			{
				values: {
					...this.state.values,
					[event.currentTarget.name]: event.currentTarget.value,
				},
			},
			() => {
				if (this.state.showErrors) {
					this.validateForm();
				}
			},
		);
	};

	validateForm = () => {
		const {values = {}, formErrors = {}} = this.state;

		const requiredFields = filter(FORM_FIELDS, field => field.required);
		const emailFields = filter(
			FORM_FIELDS,
			field => field.type === 'email',
		);
		const updatedFormErrors = {...formErrors};

		let showErrors = false;

		forEach(requiredFields, field => {
			if (!values[field.name]) {
				updatedFormErrors[field.name] = true;
				showErrors = true;
			} else {
				updatedFormErrors[field.name] = false;
			}
		});

		forEach(emailFields, field => {
			if (values[field.name].length < 6) {
				updatedFormErrors[field.name] = true;
				showErrors = true;
			} else {
				updatedFormErrors[field.name] = false;
			}
		});

		this.setState({
			showErrors,
			formErrors: updatedFormErrors,
		});

		return showErrors;
	};

	onSubmit = event => {
		event.preventDefault();

		const errors = this.validateForm();

		if (errors) return;

		this.setState({isSubmitting: true});

		recover(this.state.values.email)
			.then(res => {
				if (!res.ok) {
					throw res;
				} else {
					return res.json();
				}
			})
			.then(({token, customer}) => {
				this.setState({
					isSubmitting: false,
					isSuccess: true,
				});
			})
			.catch(error => {
				this.setState({
					isSubmitting: false,
					submitError: true,
				});
			});
	};

	render() {
		const {
			showErrors,
			formErrors,
			values,
			submitError,
			isSubmitting,
			isSuccess,
		} = this.state;

		return (
			<div className="grid-container contained mt4 mb4 mt8--lg mb18--lg">
				<Helmet title="Forgot Password - Genexa" />
				<div className="row align--center">
					<div className="col c6--md c12">
						<h1 className="serif--lg serif--xxxl--lg mb5 mb9--lg tc">
							Forgot Password
						</h1>
					</div>
				</div>

				<form title="Forgot Password" aria-label="Forgot Password"  onSubmit={this.onSubmit} className="db x mb3 mb5--lg">
					<div className="row align--center mb3 mb5--lg">
						<div className="col c6--md c12">
							<input
								name="email"
								type="email"
								onChange={this.onChange}
								value={values.email}
								className="x input--primary"
								placeholder="Email*"
							/>
							{formErrors.email && (
								<p className="mt1 color--red sans--xs fw--800">
									Please enter a valid email.
								</p>
							)}
						</div>
					</div>

					<div className="row align--center">
						<div className="col c6--sm c3--md mb1 mb0--sm">
							<button
								title="Submit"
								aria-label="Submit"
								disabled={isSubmitting || showErrors}
								type="submit"
								className={classNames('button--primary x', {
									error: showErrors,
									'cs--loading': isSubmitting,
								})}>
								Submit
							</button>
						</div>
						<div className="col c6--sm c3--md">
							<Link
								to="/account/login"
								className="button--secondary x" aria-label="Cancel">
								Cancel
							</Link>
						</div>
					</div>

					{submitError && (
						<p className="mt2 tc color--red sans--xs fw--800">
							There was an error activating your account.
						</p>
					)}

					{isSuccess && (
						<p className="mt2 tc">
							Password reset instructions have been sent to your
							email.
						</p>
					)}
				</form>
			</div>
		);
	}
}
