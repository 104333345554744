import {navigate} from 'gatsby';
import {compose, lifecycle, branch, renderNothing} from 'recompose';
import {connectToStore} from '../components/Provider';

const mapStateToProps = ({
	customer,
	isHydratingCustomer,
}) => ({
	customer,
	isHydratingCustomer,
});

export default compose(
	connectToStore(mapStateToProps),
	branch(
		props => props.isHydratingCustomer,
		renderNothing,
	),
	lifecycle({
		componentDidMount() {
			if (!this.props.customer) {
				navigate('/account/login', {
					replace: true,
				});
			}
		}
	}),
	branch(
		props => !props.customer,
		renderNothing,
	),
);
