import React from 'react';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {createStripeCustomer} from '../api/account';

const PaymentForm = ({updateRechargeCustomer, close}) => {
	const stripe = useStripe();
	const elements = useElements();

	const handleSubmit = async event => {
		// Block native form submission.
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return;
		}

		// Get a reference to a mounted CardElement. Elements knows how
		// to find your CardElement because there can only ever be one of
		// each type of element.
		const cardElement = elements.getElement(CardElement);

		// Use your card Element with other Stripe.js APIs
		const {error, paymentMethod} = await stripe.createPaymentMethod({
			type: 'card',
			card: cardElement,
		});

		try {
			let customer = await createStripeCustomer({
				paymentMethod: paymentMethod.id,
			});
			customer = await customer.json();

			updateRechargeCustomer({
				customerData: {stripe_customer_token: customer.customerId},
			});

			close();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<form title="Subscriptions" aria-label="Subscriptions" onSubmit={handleSubmit}>
			<div className="row">
				<div className="col c6--md">
					<CardElement
						className="input--primary mb5"
						options={{
							style: {
								base: {
									lineHeight: '44px',
									fontSize: '16px',
									fontFamily:
										"'Maison', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, sans-serif",
									color: '#000',
									'::placeholder': {
										color: '#000',
									},
								},
								invalid: {
									color: '#9e2146',
								},
							},
						}}
					/>
				</div>
			</div>
			<button
				title="Update Card"
				aria-label="Update Card" 
				className="button--primary"
				type="submit"
				disabled={!stripe}>
				Update Card
			</button>
		</form>
	);
};

export default PaymentForm;
