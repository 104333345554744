import React, {Component} from 'react';
import classNames from 'classnames';
import {compose} from 'recompose';
import {connectToStore} from '../components/Provider';
import Select from '../components/Select';
import requireCustomer from '../middleware/requireCustomer';
import {decode} from '../utils/shopify-gid';
import find from 'lodash/find';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import countryOptions from '../data/countryOptions.json';
import pick from 'lodash/pick';

const COUNTRIES = countryOptions.map(c => ({
	title: c.country,
	value: c.country,
}));
const INITIAL_STATE = {
	address: null,
	values: {},
	isDefault: false,
	formErrors: {},
	showErrors: false,
	isSuccess: false,
	isSubmitting: false,
	submitError: false,
};

const ADDRESS_FIELDS = [
	{
		name: 'first_name',
		type: 'text',
		required: true,
	},
	{
		name: 'last_name',
		type: 'text',
		required: true,
	},
	{
		name: 'address1',
		type: 'text',
		required: true,
	},
	{
		name: 'address2',
		type: 'text',
		required: false,
	},
	{
		name: 'city',
		type: 'text',
		required: true,
	},
	{
		name: 'country',
		type: 'text',
		required: true,
	},
	{
		name: 'province',
		type: 'text',
		required: false,
	},
	{
		name: 'zip',
		type: 'text',
		required: true,
	},
	{
		name: 'phone',
		type: 'text',
		required: false,
	},
];

class ShippingAddressEdit extends Component {
	constructor() {
		super();

		this.state = {...INITIAL_STATE};
	}

	componentDidMount = () => {
		if (this.props.address) {
			this.hydrateAddress();
		}
	};

	componentDidUpdate = previousProps => {
		if (this.props.address && !previousProps.address) {
			this.hydrateAddress();
		}
	};

	hydrateAddress = () => {
		const {
			id, // eslint-disable-line
			...values
		} = this.props.address;

		this.setState({
			address: this.props.address,
			values,
			isDefault: this.props.isDefault,
			errors: map(this.props.address.map, v => false),
		});
	};

	onChangeInput = event => {
		this.setState(
			{
				values: {
					...this.state.values,
					[event.currentTarget.name]: event.currentTarget.value,
				},
			},
			() => {
				if (this.state.showErrors) {
					this.validateForm();
				}
			},
		);
	};

	onChangeCountry = country => {
		const {values = {}} = this.state;

		this.setState(
			{
				values: {
					...values,
					country,
					province: '',
				},
			},
			() => {
				if (this.state.showErrors) {
					this.validateForm();
				}
			},
		);
	};

	onChangeProvince = province => {
		const {values = {}} = this.state;

		this.setState(
			{
				values: {
					...values,
					province,
				},
			},
			() => {
				if (this.state.showErrors) {
					this.validateForm();
				}
			},
		);
	};

	onToggleIsDefault = () => {
		this.setState(
			{
				isDefault: !this.state.isDefault,
			},
			() => {
				if (this.state.showErrors) {
					this.validateForm();
				}
			},
		);
	};

	validateForm = () => {
		const {values = {}, formErrors = {}} = this.state;

		const requiredFields = filter(ADDRESS_FIELDS, field => field.required);
		const updatedFormErrors = {...formErrors};

		let showErrors = false;

		forEach(requiredFields, field => {
			if (!values[field.name]) {
				updatedFormErrors[field.name] = true;
				showErrors = true;
			} else {
				updatedFormErrors[field.name] = false;
			}
		});

		this.setState({
			showErrors,
			formErrors: updatedFormErrors,
		});

		return showErrors;
	};

	onSubmit = event => {
		event.preventDefault();

		const errors = this.validateForm();

		if (errors) return;

		this.setState({
			isSubmitting: true,
			submitError: false,
		});

		const addressFields = ADDRESS_FIELDS.map(({name}) => name);
		const shippingAddress = pick(this.state.values, addressFields);

		this.props
			.updateShippingAddress({
				rechargeAccessToken: this.props.rechargeAccessToken,
				addressId: this.props.address.id,
				shippingAddress,
			})
			.then(res => {
				if (!res.ok) {
					throw res;
				} else {
					return res.json();
				}
			})
			.then(address => {
				this.setState({
					isSuccess: true,
					isSubmitting: false,
					submitError: false,
				});
				this.props.close();
			})
			.catch(error => {
				this.setState({
					isSuccess: false,
					isSubmitting: false,
					submitError: error,
				});
			});
	};

	render() {
		const {
			address,
			values,
			formErrors,
			showErrors,
			isSubmitting,
			submitError,
		} = this.state;

		const currentCountryOption = values.country
			? find(countryOptions, {country: values.country})
			: null;
		const currentProvinceOptions = currentCountryOption
			? currentCountryOption.provinces.map(p => ({
					title: p[0],
					value: p[0],
			  }))
			: [];

		const form = address && (
			<React.Fragment>
				<form  title="Update Shipping Address" aria-label="Update Shipping Address"  onSubmit={this.onSubmit} className="mt5">
					<div className="row">
						<div className="col">
							<h3 className="sans--md fw--800 mb3">
								Edit Shipping Address
							</h3>
						</div>
					</div>
					<div className="row">
						<div className="col c6 mb2">
							<input
								name="first_name"
								value={values.first_name}
								onChange={this.onChangeInput}
								className="x input--primary"
								placeholder="First Name"
							/>
							{showErrors && formErrors.first_name && (
								<p className="color--red sans--xs">
									Please enter a first name.
								</p>
							)}
						</div>
						<div className="col c6 mb2">
							<input
								name="last_name"
								value={values.last_name}
								onChange={this.onChangeInput}
								className="x input--primary"
								placeholder="Last Name"
							/>
							{showErrors && formErrors.last_name && (
								<p className="mt1 color--red sans--xs fw--800">
									Please enter a last name.
								</p>
							)}
						</div>
						<div className="col c6 mb2">
							<input
								name="company"
								value={values.company}
								onChange={this.onChangeInput}
								className="x input--primary"
								placeholder="Company"
							/>
						</div>
						<div className="col c6 mb2">
							<input
								name="phone"
								value={values.phone}
								onChange={this.onChangeInput}
								className="x input--primary"
								placeholder="Phone"
							/>
							{showErrors && formErrors.phone && (
								<p className="mt1 color--red sans--xs fw--800">
									Please enter a phone number.
								</p>
							)}
						</div>
						<div className="col c6 mb2">
							<input
								name="address1"
								value={values.address1}
								onChange={this.onChangeInput}
								className="x input--primary"
								placeholder="Street Address*"
							/>
							{showErrors && formErrors.address1 && (
								<p className="mt1 color--red sans--xs fw--800">
									Please enter a street address.
								</p>
							)}
						</div>
						<div className="col c6 mb2">
							<input
								name="address2"
								value={values.address2}
								onChange={this.onChangeInput}
								className="x input--primary"
								placeholder="Suite/Apt"
							/>
							{showErrors && formErrors.address2 && (
								<p className="mt1 color--red sans--xs fw--800">
									Please enter an apartment number.
								</p>
							)}
						</div>
						<div className="col c6 mb2">
							<input
								name="city"
								value={values.city}
								onChange={this.onChangeInput}
								className="x input--primary"
								placeholder="City*"
							/>
							{showErrors && formErrors.city && (
								<p className="mt1 color--red sans--xs fw--800">
									Please enter a city.
								</p>
							)}
						</div>
						<div className="col c6 mb2">
							<Select
								label="Country*"
								value={values.country}
								onChange={this.onChangeCountry}
								triggerClassName="input--primary x df fdr jcb aic"
								options={COUNTRIES}
								dropdownAlignment="top left right"
							/>
							{showErrors && formErrors.country && (
								<p className="mt1 color--red sans--xs fw--800">
									Please select a country.
								</p>
							)}
						</div>
						<div className="col c6 mb2">
							<Select
								label="Province*"
								value={values.province}
								onChange={this.onChangeProvince}
								triggerClassName="input--primary x df fdr jcb aic"
								options={currentProvinceOptions}
								dropdownAlignment="top left right"
							/>
							{showErrors && formErrors.province && (
								<p className="mt1 color--red sans--xs fw--800">
									Please select a province.
								</p>
							)}
						</div>
						<div className="col c6 mb2">
							<input
								name="zip"
								value={values.zip}
								onChange={this.onChangeInput}
								className="x input--primary"
								placeholder="ZIP*"
							/>
							{showErrors && formErrors.zip && (
								<p className="mt1 color--red sans--xs fw--800">
									Please enter a ZIP.
								</p>
							)}
						</div>

						<div className="col c10">
							<div className="mb1">
								<div className="mr2 dib">
									<button
										title="Edit Shipping Address"
										aria-label="Edit Shipping Address"
										type="submit"
										disabled={isSubmitting}
										className={classNames(
											'button--primary',
											{
												error: showErrors,
												'cs--loading': isSubmitting,
											},
										)}>
										Edit Shipping Address
									</button>
								</div>
								<button
									title="Cancel"
									aria-label="Cancel"
									onClick={this.props.close}
									disabled={isSubmitting}
									className={classNames(
										'link--underline-invert-small dib',
									)}>
									Cancel
								</button>
							</div>
						</div>

						{submitError && (
							<p className="mt2 tc color--red sans--xs">
								{submitError.status === 409
									? 'That address is already being used.'
									: 'There was an error creating your address.'}
							</p>
						)}
					</div>
				</form>
			</React.Fragment>
		);

		return (
			<div className=" mt3 mb6 mt8--lg mb8--lg">
				<div className="row align--center">
					<div className="col">
						<div className="mb4">
							<button
								title="Back to Subscriptions"
								aria-label="Back to Subscriptions"
								className="fw--800 df aic"
								onClick={this.props.close}>
								<img
									className="db"
									src="/images/icon-arrow-address.svg"
									alt="Arrow"
								/>
								<span className="ml1 db">
									Back to Subscriptions
								</span>
							</button>
						</div>
					</div>
				</div>

				{!address && <p>Loading…</p>}

				{form}
			</div>
		);
	}
}

const mapStateToProps = (
	{customerToken, addresses = [], defaultAddress, customer},
	{id},
) => {
	const address = find(addresses, a => decode(a.id).id === id);
	const isDefault = defaultAddress
		? decode(address.id).id === decode(defaultAddress.id).id
		: false;

	return {
		customerToken,
		address,
		isDefault,
		customer,
	};
};

const mapStoreToProps = ({logout, updateShippingAddress}) => ({
	logout,
	updateShippingAddress,
});

export default compose(
	requireCustomer,
	// hydrateCustomerAccount,
	connectToStore(mapStateToProps, mapStoreToProps),
)(ShippingAddressEdit);
