import React, {useState} from 'react';
import {compose} from 'recompose';
import {connectToStore} from '../components/Provider';
import SkipOrderModal from '../components/SkipOrderModal';
import hydrateCustomerSubscriptions from '../middleware/hydrateCustomerSubscriptions';
import requireCustomer from '../middleware/requireCustomer';
import format from 'date-fns/format';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {DateUtils} from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import parse from 'date-fns/parse';
import pluralize from 'magic-tricks/lib/pluralize';

const INTERVAL_OPTIONS = {
	day: [30, 60, 90],
	week: [1, 2, 3, 4],
	month: [1, 2, 3, 4],
};

const SubscriptionsEdit = ({
	rechargeAccessToken,
	id,
	subscriptionId,
	product_title,
	quantity,
	order_interval_frequency,
	order_interval_unit,
	next_charge_scheduled_at,
	charge_interval_frequency,
	address,
	address_id,
	onClose,
	editShipping,
	openCancelFlow,
	hydrateCustomerSubscriptions,
	updateSubscriptionDetails,
	skipNextCharge,
}) => {
	const [skipConfirming, setSkipConfirming] = useState(false);
	const [subscriptionDetails, setSubscriptionDetails] = useState({
		id,
		quantity,
		order_interval_frequency,
		order_interval_unit,
		next_charge_scheduled_at,
		charge_interval_frequency,
	});

	const FORMAT = 'MMM d, yyyy';

	const parseDate = (str, fmt, locale) => {
		const parsed = parse(str, fmt, new Date(), {locale});
		if (DateUtils.isDate(parsed)) {
			return parsed;
		}
		return undefined;
	};

	const formatDate = (date, fmt, locale) => {
		return format(date, fmt, {locale});
	};

	const onSkip = event => {
		skipNextCharge({
			subscriptionId: id,
		})
			.then(() => {
				hydrateCustomerSubscriptions();
				setSkipConfirming(true);
			})
			.catch(error => {
				console.log(error);
			});
	};

	const onSubmit = event => {
		event.preventDefault();

		updateSubscriptionDetails({
			subscriptionDetails,
			addressId: address_id,
		}).then(() => {
			onClose();
		});
	};

	return (
		<div className="bg--gray-bg p2">
			<form  title="Update subscription" aria-label="Update subscription"  className="db x" onSubmit={onSubmit}>
				<div className="subscription-list__grid">
					<p className="subscription-list__grid-item-lg pt2 pb2 ">
						Product
					</p>
					<p className="pt2 pb2">Quantity</p>
					<p className="pt2 pb2 show--md">Frequency</p>
					<p className="subscription-list__grid-item-md pt2 pb2 show--md">
						Next Shipment
					</p>
					<p className="subscription-list__grid-item-lg pt2 pb2 show--md">
						Shipping Address
					</p>

					<hr className="subscription-list__grid-row-divider " />
					<p className="subscription-list__grid-item-lg pt2 pb2">
						{product_title}
					</p>
					<div>
						<select
							className="select--primary x"
							name="quantity"
							value={subscriptionDetails.quantity}
							onChange={e =>
								setSubscriptionDetails({
									...subscriptionDetails,
									quantity: Number(e.target.value),
								})
							}>
							{Array.from(Array(10).keys()).map((o, i) => (
								<option key={i + 1} value={i + 1}>
									{i + 1}
								</option>
							))}
						</select>
					</div>
					<p className="pt2 pb2 hide--md">Frequency</p>
					<p className="pt2 pb2 hide--md">Next Shipment</p>
					<hr className="subscription-list__grid-row-divider hide--md" />
					<div>
						<select
							className="select--primary x"
							name="frequency"
							value={subscriptionDetails.order_interval_frequency}
							onChange={e => {
								setSubscriptionDetails({
									...subscriptionDetails,
									order_interval_frequency: Number(
										e.target.value,
									),
									charge_interval_frequency: Number(
										e.target.value,
									),
								});
							}}>
							{INTERVAL_OPTIONS[order_interval_unit].map(
								option => (
									<option
										key={`frequency-select-${option}`}
										value={option}>
										{option}{' '}
										{pluralize(order_interval_unit, option)}
									</option>
								),
							)}
						</select>
					</div>
					<p className="subscription-list__grid-item-md x">
						<DayPickerInput
							className="select--primary"
							formatDate={formatDate}
							format={FORMAT}
							parseDate={parseDate}
							placeholder={`${format(
								new Date(next_charge_scheduled_at),
								FORMAT,
							)}`}
							onDayChange={day =>
								setSubscriptionDetails({
									...subscriptionDetails,
									next_charge_scheduled_at: day,
								})
							}
							dayPickerProps={{
								disabledDays: {
									before: new Date(),
								},
							}}
						/>
					</p>
					<p className="subscription-list__grid-item-lg pt2 pb2 hide--md">
						Shipping Address
					</p>

					<hr className="subscription-list__grid-row-divider hide--md" />

					<div className="pt2 pb2 subscription-list__grid-item-lg">
						<p>
							{address.address1} {address.address2}
						</p>
						<p>
							{address.city}, {address.province} {address.country}
						</p>
						<p className="mb1">{address.country}</p>
						<button
							title="Edit Shipping Address" 
							aria-label="Edit Shipping Address"
							type="button"
							className="link--underline-invert-small"
							onClick={editShipping}>
							Edit Shipping Address
						</button>
					</div>
				</div>
				<div className="mb2 hide--md">
					<button title="Save" aria-label="Save" type="submit" className="button--primary">
						Save
					</button>
				</div>
				<div className="df fdr aic x mb2">
					<div className="show--md mr2">
						<button title="Save" aria-label="Save"  type="submit" className="button--primary db">
							Save
						</button>
					</div>
					<button
						title="Cancel Subscription" 
						aria-label="Cancel Subscription" 
						type="button"
						className="mr2 link--underline-invert-small"
						onClick={openCancelFlow}>
						Cancel Subscription
					</button>
					<button
						title="Skip Subscription" 
						aria-label="Skip Subscription" 
						type="button"
						className="link--underline-invert-small"
						onClick={onSkip}>
						Skip Subscription
					</button>
				</div>
			</form>

			{skipConfirming && (
				<SkipOrderModal
					nextOrderDate={next_charge_scheduled_at}
					onClose={() => {
						setSkipConfirming(false);
						onClose();
					}}
				/>
			)}
		</div>
	);
};

const mapStateToProps = () => ({});

const mapStoreToProps = ({
	updateSubscriptionDetails,
	skipNextCharge,
	hydrateCustomerSubscriptions,
}) => ({
	updateSubscriptionDetails,
	skipNextCharge,
	hydrateCustomerSubscriptions,
});

export default compose(
	hydrateCustomerSubscriptions,
	requireCustomer,
	connectToStore(mapStateToProps, mapStoreToProps),
)(SubscriptionsEdit);
