import {compose, lifecycle} from 'recompose';
import {connectToStore} from '../components/Provider';

const mapStateToProps = () => ({});
const mapStoreToProps = ({hydrateCustomerSubscriptions}) => ({hydrateCustomerSubscriptions});

export default compose(
	connectToStore(mapStateToProps, mapStoreToProps),
	lifecycle({
		componentDidMount() {
			this.props.hydrateCustomerSubscriptions();
		}
	}),
);
