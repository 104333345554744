import React, {Component} from 'react';
import classNames from 'classnames';
import {Link, navigate} from 'gatsby';
import {compose} from 'recompose';
import {connectToStore} from '../components/Provider';
import Select from '../components/Select';
import AccountMenu from '../components/AccountMenu';
import requireCustomer from '../middleware/requireCustomer';
import hydrateCustomerAccount from '../middleware/hydrateCustomerAccount';
import {decode} from '../utils/shopify-gid';
import find from 'lodash/find';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import countryOptions from '../data/countryOptions.json';
import {createAddress} from '../api/account';
import Helmet from 'react-helmet';
import Radio from '../components/Radio';

const COUNTRIES = countryOptions.map(c => ({
	title: c.country,
	value: c.country,
}));

const INITIAL_STATE = {
	address: null,
	values: {},
	isDefault: false,
	formErrors: {},
	showErrors: false,
	isSubmitting: false,
	submitError: false,
};

const ADDRESS_FIELDS = [
	{
		name: 'firstName',
		type: 'text',
		required: true,
	},
	{
		name: 'lastName',
		type: 'text',
		required: true,
	},
	{
		name: 'address1',
		type: 'text',
		required: true,
	},
	{
		name: 'address2',
		type: 'text',
		required: false,
	},
	{
		name: 'city',
		type: 'text',
		required: true,
	},
	{
		name: 'country',
		type: 'text',
		required: true,
	},
	{
		name: 'province',
		type: 'text',
		required: false,
	},
	{
		name: 'zip',
		type: 'text',
		required: true,
	},
	{
		name: 'phone',
		type: 'text',
		required: false,
	},
];

class AddressNew extends Component {
	constructor() {
		super();

		this.state = {...INITIAL_STATE};
	}

	onChangeInput = event => {
		this.setState(
			{
				values: {
					...this.state.values,
					[event.currentTarget.name]: event.currentTarget.value,
				},
			},
			() => {
				if (this.state.showErrors) {
					this.validateForm();
				}
			},
		);
	};

	onChangeCountry = country => {
		const {values = {}} = this.state;

		this.setState(
			{
				values: {
					...values,
					country,
					province: '',
				},
			},
			() => {
				if (this.state.showErrors) {
					this.validateForm();
				}
			},
		);
	};

	onChangeProvince = province => {
		const {values = {}} = this.state;

		this.setState(
			{
				values: {
					...values,
					province,
				},
			},
			() => {
				if (this.state.showErrors) {
					this.validateForm();
				}
			},
		);
	};

	onToggleIsDefault = () => {
		this.setState(
			{
				isDefault: !this.state.isDefault,
			},
			() => {
				if (this.state.showErrors) {
					this.validateForm();
				}
			},
		);
	};

	validateForm = () => {
		const {values = {}, formErrors = {}} = this.state;

		const requiredFields = filter(ADDRESS_FIELDS, field => field.required);
		const updatedFormErrors = {...formErrors};

		let showErrors = false;

		forEach(requiredFields, field => {
			if (!values[field.name]) {
				updatedFormErrors[field.name] = true;
				showErrors = true;
			} else {
				updatedFormErrors[field.name] = false;
			}
		});

		this.setState({
			showErrors,
			formErrors: updatedFormErrors,
		});

		return showErrors;
	};

	onSubmit = event => {
		event.preventDefault();

		const errors = this.validateForm();

		if (errors) return;

		this.setState({
			isSubmitting: true,
			submitError: false,
		});

		createAddress(
			this.props.customerToken,
			this.state.values,
			this.state.isDefault,
		)
			.then(res => {
				if (!res.ok) {
					throw res;
				} else {
					return res.json();
				}
			})
			.then(({addressId}) => {
				navigate('/account/addresses');
			})
			.catch(error => {
				this.setState({
					isSubmitting: false,
					submitError: error,
				});
			});
	};

	render() {
		const {
			values,
			isDefault,
			formErrors,
			showErrors,
			isSubmitting,
			submitError,
		} = this.state;

		const currentCountryOption = values.country
			? find(countryOptions, {country: values.country})
			: null;
		const currentProvinceOptions = currentCountryOption
			? currentCountryOption.provinces.map(p => ({
					title: p[0],
					value: p[0],
			  }))
			: [];

		const form = (
			<form title="Update Address" aria-label="Update Address"  onSubmit={this.onSubmit} className="db x">
				<div className="row align--center">
					<div className="col c6--md mb2">
						<input
							name="firstName"
							value={values.firstName}
							onChange={this.onChangeInput}
							className="x input--primary"
							placeholder="First Name"
						/>
						{showErrors && formErrors.firstName && (
							<p className="mt1 color--red sans--xs fw--800">
								Please enter a first name.
							</p>
						)}
					</div>
					<div className="col c6--md mb2">
						<input
							name="lastName"
							value={values.lastName}
							onChange={this.onChangeInput}
							className="x input--primary"
							placeholder="Last Name"
						/>
						{showErrors && formErrors.lastName && (
							<p className="mt1 color--red sans--xs fw--800">
								Please enter a last name.
							</p>
						)}
					</div>
					<div className="col c6--md mb2">
						<input
							name="company"
							value={values.company}
							onChange={this.onChangeInput}
							className="x input--primary"
							placeholder="Company"
						/>
					</div>
					<div className="col c6--md mb2">
						<input
							name="phone"
							value={values.phone}
							onChange={this.onChangeInput}
							className="x input--primary"
							placeholder="Phone"
						/>
						{showErrors && formErrors.phone && (
							<p className="mt1 color--red sans--xs fw--800">
								Please enter a phone number.
							</p>
						)}
					</div>
					<div className="col c6--md mb2">
						<input
							name="address1"
							value={values.address1}
							onChange={this.onChangeInput}
							className="x input--primary"
							placeholder="Street Address*"
						/>
						{showErrors && formErrors.address1 && (
							<p className="mt1 color--red sans--xs fw--800">
								Please enter a street address.
							</p>
						)}
					</div>
					<div className="col c6--md mb2">
						<input
							name="address2"
							value={values.address2}
							onChange={this.onChangeInput}
							className="x input--primary"
							placeholder="Suite/Apt"
						/>
						{showErrors && formErrors.address2 && (
							<p className="mt1 color--red sans--xs fw--800">
								Please enter an apartment number.
							</p>
						)}
					</div>
					<div className="col c6--md mb2">
						<input
							name="city"
							value={values.city}
							onChange={this.onChangeInput}
							className="x input--primary"
							placeholder="City*"
						/>
						{showErrors && formErrors.city && (
							<p className="mt1 color--red sans--xs fw--800">
								Please enter a city.
							</p>
						)}
					</div>
					<div className="col c6--md mb2">
						<Select
							label="Country*"
							value={values.country}
							onChange={this.onChangeCountry}
							triggerClassName="input--primary x df fdr jcb aic"
							options={COUNTRIES}
							dropdownAlignment="top left right"
						/>
						{showErrors && formErrors.country && (
							<p className="mt1 color--red sans--xs fw--800">
								Please select a country.
							</p>
						)}
					</div>
					<div className="col c6--md mb2">
						<Select
							label="Province*"
							value={values.province}
							onChange={this.onChangeProvince}
							triggerClassName="input--primary x df fdr jcb aic"
							options={currentProvinceOptions}
							dropdownAlignment="top left right"
						/>
						{showErrors && formErrors.province && (
							<p className="mt1 color--red sans--xs fw--800">
								Please select a province.
							</p>
						)}
					</div>
					<div className="col c6--md mb2">
						<input
							name="zip"
							value={values.zip}
							onChange={this.onChangeInput}
							className="x input--primary"
							placeholder="ZIP*"
						/>
						{showErrors && formErrors.zip && (
							<p className="mt1 color--red sans--xs fw--800">
								Please enter a ZIP.
							</p>
						)}
					</div>
				</div>

				<div className="df fdr aic mb4">
					<Radio
						onClick={this.onToggleIsDefault}
						className="fg0"
						active={isDefault}
					/>
					<label onClick={this.onToggleIsDefault} className="fg1 pl1">
						Set as default address
					</label>
				</div>

				<div className="df fdr aic jcs">
					<div className="mr2">
						<button
							type="submit"
							disabled={isSubmitting}
							title="Create Address"
							aria-label="Create Address"
							className={classNames('button--primary db', {
								error: showErrors,
								'cs--loading': isSubmitting,
							})}>
							Create Address
						</button>
					</div>
					<Link
						to="/account/addresses"
						aria-label="Cancel"
						disabled={isSubmitting}
						className={classNames(
							'link--underline-invert-small db',
						)}>
						Cancel
					</Link>
				</div>

				{submitError && (
					<p className="mt2 tc color--red sans--xs fw--800">
						{submitError.status === 409
							? 'That address is already being used.'
							: 'There was an error creating your address.'}
					</p>
				)}
			</form>
		);

		return (
			<div className="mt3 mb6 mt8--lg mb8--lg">
				<Helmet title="New Address - Genexa" />
				<AccountMenu />

				<div className="grid-container contained">
					<div className="row align--center">
						<div className="col c10--lg">
							<p className="mt5 mb3">
								<Link
									className="fw--800 df aic link--opacity"
									to="/account/addresses" aria-label="Back to Addresses">
									<img
										className="db"
										src="/images/icon-arrow-address.svg"
										alt="Back to Addresses"
									/>
									<span className="ml1 db">
										Back to Addresses
									</span>
								</Link>
							</p>
							<h2 className="serif--lg serif--xxl--lg mb5 mb8--lg">
								Add New Address
							</h2>

							{form}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (
	{customerToken, addresses = [], defaultAddress, customer},
	{id},
) => {
	const address = find(addresses, a => decode(a.id).id === id);

	return {
		customerToken,
		address,
		customer,
	};
};

export default compose(
	requireCustomer,
	hydrateCustomerAccount,
	connectToStore(mapStateToProps),
)(AddressNew);
