import React from 'react';
import {Router} from '@reach/router';
import OrderList from './OrderList';
import Login from './Login';
import Register from './Register';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import ActivateAccount from './ActivateAccount';
import InvalidToken from './InvalidToken';
import AddressList from './AddressList';
import AddressNew from './AddressNew';
import AddressEdit from './AddressEdit';
import SubscriptionsList from './SubscriptionsList';
import SubscriptionsBilling from './SubscriptionsBilling';
import Helmet from 'react-helmet';

const Route = ({children, path}) => <div>{children}</div>;

export default ({pageContext}) =>{
	const {pageTitle} = pageContext;

	return (
		<div>
			<Helmet title='Account - Genexa'/>
			<Router>
				<Route path="/account">
					<OrderList path="/" />
					<AddressList path="/addresses" />
					<AddressNew path="/addresses/new" />
					<AddressEdit path="/addresses/:id" />

					<SubscriptionsList
						path="/subscriptions"
						cancelationReasons={pageContext.cancelationReasons}
					/>
					<SubscriptionsBilling path="/subscriptions/billing" />

					<Login path="/login" />
					<Register path="/register" />
					<ForgotPassword path="/forgot" />
					<ResetPassword path="/reset/:id/:token" />
					<ActivateAccount path="/activate/:id/:token" />
					<InvalidToken path="/invalid_token" />
				</Route>
			</Router>

			<div className="grid-container mt6 mb6 mt12--lg mb12--lg">
				<div className="row align--center">
					<div className="col c10--lg">
						<hr />
					</div>
				</div>
			</div>
		</div>
	);
}
