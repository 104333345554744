import React, {useState} from 'react';
import classNames from 'classnames';
import {cancelSubscription} from '../api/account';
import {Transition} from 'react-transition-group';

export const MODAL_TRANSITION_DURATION = 800;

const UNDERLAY_TRANSITION_STYLES = {
	default: {
		transition: `opacity ${MODAL_TRANSITION_DURATION}ms ease, transform ${MODAL_TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	},
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
	},
	exiting: {
		opacity: 0,
	},
	exited: {
		opacity: 0,
	},
};

const MODAL_TRANSITION_STYLES = {
	default: {
		transition: `opacity ${MODAL_TRANSITION_DURATION}ms ease, transform ${MODAL_TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	},
	entering: {
		opacity: 0,
		transform: 'translate(-50%, calc(-50% + 100px))',
	},
	entered: {
		opacity: 1,
		transform: 'translate(-50%, -50%)',
	},
	exiting: {
		opacity: 0,
		transform: 'translate(-50%, calc(-50% + 100px))',
	},
	exited: {
		opacity: 0,
		transform: 'translate(-50%, calc(-50% + 100px))',
	},
};

export default ({
	rechargeAccessToken,
	isOpen,
	productTitle,
	subscriptionId,
	cancelationReasons,
	onClose,
	onCancel,
}) => {
	const [isCancelling, setIsCancelling] = useState(false);
	const [activeReason, setActiveReason] = useState(null);

	const onClickCancel = () => {
		setIsCancelling(true);

		cancelSubscription({
			rechargeAccessToken,
			subscriptionId,
			cancelationReason: activeReason,
		})
			.then(res => {
				if (!res.ok) {
					throw res;
				}
			})
			.then(() => {
				setIsCancelling(false);
				onCancel();
				onClose();
			})
			.catch(error => {
				setIsCancelling(false);
				console.log(error);
			});
	};

	return (
		<Transition
			in={isOpen}
			mountOnEnter
			unmountOnExit
			timeout={{
				enter: 100,
				exit: MODAL_TRANSITION_DURATION,
			}}>
			{status => (
				<React.Fragment>
					<div
						className="cancelation-modal__underlay pf fill"
						style={{
							...UNDERLAY_TRANSITION_STYLES.default,
							...UNDERLAY_TRANSITION_STYLES[status],
						}}
						onClick={onClose}
					/>
					<div
						className="cancelation-modal pf p2 bg--white"
						style={{
							...MODAL_TRANSITION_STYLES.default,
							...MODAL_TRANSITION_STYLES[status],
						}}>
						{/* first step */}
						{!activeReason && (
							<div>
								<p className="fw--800 mb2">
									Select a reason for cancelling your
									subscription.
								</p>

								<div className="mb3">
									{cancelationReasons.map(reason => (
										<button
											title={reason}
											aria-label={reason}
											className="db link--underline-small"
											onClick={() =>
												setActiveReason(reason)
											}>
											{reason}
										</button>
									))}
								</div>

								<button
									title="Back"
									aria-label="Back"
									className="button--primary"
									onClick={onClose}>
									Back
								</button>
							</div>
						)}

						{/* second step */}
						{activeReason && (
							<div>
								<p className="fw--800 sans--lg mb2">
									{productTitle}
								</p>

								<div className="df jcb aic">
									<button
										title="Cancel Subscription"
										aria-label="Cancel Subscription"
										disabled={isCancelling}
										className={classNames(
											'button--primary',
											{
												loading: isCancelling,
											},
										)}
										onClick={onClickCancel}>
										Cancel Subscription
									</button>
									<button
										title="I have changed my mind"
										aria-label="I have changed my mind"
										className="link--underline-small"
										onClick={onClose}>
										I've Changed My Mind
									</button>
								</div>
							</div>
						)}

						<div className="pa top right p2 pen">
							<button
								title="Close"
								aria-label="Close"
								onClick={onClose}
								className="db link--opacity pea">
								<img
									className="db"
									src="/images/icon-modal-close.svg"
									alt="Close"
								/>
							</button>
						</div>
					</div>
				</React.Fragment>
			)}
		</Transition>
	);
};
