import React, {useState} from 'react';
import {compose} from 'recompose';
import {connectToStore} from '../components/Provider';
import requireCustomer from '../middleware/requireCustomer';
import hydrateCustomerSubscriptions from '../middleware/hydrateCustomerSubscriptions';
import Helmet from 'react-helmet';
import AccountMenu from '../components/AccountMenu';
import SubscriptionsSubmenu from '../components/SubscriptionsSubmenu';
import CancelationModal from '../components/CancelationModal';
import SubscriptionEdit from '../components/SubscriptionEdit';
import ShippingAddressEdit from '../components/ShippingAddressEdit';
import SubscriptionDetail from '../components/SubscriptionDetail';

const SubscriptionsList = ({
	rechargeAccessToken,
	subscriptions = [],
	isFetchingSubscriptions,
	cancelationReasons,
	hydrateCustomerSubscriptions,
}) => {
	const [cancelFlowIndex, setCancelFlowIndex] = useState(null);
	const [editFlowIndex, setEditFlowIndex] = useState(null);
	const [shippingFlowIndex, setShippingFlowIndex] = useState(null);

	const subscriptionList = subscriptions.map((subscription, index) => (
		<div key={index} className="mb2">
			{shippingFlowIndex === null && editFlowIndex === null && (
				<SubscriptionDetail
					rechargeAccessToken={rechargeAccessToken}
					subscription={subscription}
					onCancel={() => setCancelFlowIndex(index)}
					onEdit={() => setEditFlowIndex(index)}
					onReactivate={hydrateCustomerSubscriptions}
				/>
			)}

			{shippingFlowIndex === null && editFlowIndex === index && (
				<SubscriptionEdit
					rechargeAccessToken={rechargeAccessToken}
					subscriptionId={subscription.id}
					onClose={() => {
						setCancelFlowIndex(null);
						setEditFlowIndex(null);
						setShippingFlowIndex(null);
						hydrateCustomerSubscriptions();
					}}
					editShipping={() => setShippingFlowIndex(index)}
					openCancelFlow={() => setCancelFlowIndex(index)}
					{...subscription}
				/>
			)}

			<CancelationModal
				rechargeAccessToken={rechargeAccessToken}
				isOpen={shippingFlowIndex === null && cancelFlowIndex === index}
				productTitle={subscription.product_title}
				subscriptionId={subscription.id}
				cancelationReasons={cancelationReasons}
				onClose={() => {
					setCancelFlowIndex(null);
					setEditFlowIndex(null);
					setShippingFlowIndex(null);
					hydrateCustomerSubscriptions();
				}}
				onCancel={hydrateCustomerSubscriptions}
			/>

			{shippingFlowIndex === index && (
				<ShippingAddressEdit
					rechargeAccessToken={rechargeAccessToken}
					close={() => {
						setCancelFlowIndex(null);
						setEditFlowIndex(null);
						setShippingFlowIndex(null);
					}}
					{...subscription}
				/>
			)}
		</div>
	));

	const loadingState = isFetchingSubscriptions && !subscriptions.length && (
		<div className="bg--gray-bg">
			<p className="p4 p10--lg tc fw--800">Loading…</p>
		</div>
	);

	const emptyState = !isFetchingSubscriptions && !subscriptions.length && (
		<div className="bg--gray-bg">
			<p className="p4 p10--lg tc fw--800">
				You don't have any subscriptions yet!
			</p>
		</div>
	);

	return (
		<div className="mt3 mb6 mt8--lg mb8--lg">
			<Helmet title="Active Subscriptions - Genexa" />
			<AccountMenu />

			<div className="grid-container contained mt3 mt5--lg">
				<div className="row align--center">
					<div className="col c10--lg">
						<SubscriptionsSubmenu />

						{loadingState}
						{emptyState}
						{subscriptionList}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = ({
	rechargeAccessToken,
	subscriptions,
	isFetchingSubscriptions,
	customer,
}) => ({
	rechargeAccessToken,
	subscriptions,
	isFetchingSubscriptions,
	customer,
});

const mapStoreToProps = ({hydrateCustomerSubscriptions}) => ({
	hydrateCustomerSubscriptions,
});

export default compose(
	requireCustomer,
	hydrateCustomerSubscriptions,
	connectToStore(mapStateToProps, mapStoreToProps),
)(SubscriptionsList);
